.Body--container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup--container {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 30px 0 30px 0;
  width: 600px;
  margin: 10vh 0 10vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.form--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.labels {
  color: #333333;
  font-size: 17px;
  margin-bottom: 5px;
}

.signup--container > h2 {
  color: #7f0518;
  font-size: 28px;
}

.input--container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.inputFrame {
  width: 100%;
  height: 30px;
  border: solid 1px lightgray;
  border-radius: 5px;
}

.inputFrame--red {
  width: 100%;
  height: 30px;
  border: solid 1px red;
  border-radius: 5px;
}

.submit--btn {
  width: 80%;
  height: 50px;
  background-color: #7f0518;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  margin: 50px 0 30px 0;
  cursor: pointer;
}

.error--info {
  color: red;
  margin-top: 10px;
  margin-bottom: 0;
}