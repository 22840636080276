.MUJI--logo {
  font-size: 40px;
}

.Navbar--upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 10vw 0 10vw;
  /* border: solid 2px red; */
}

.icons--container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: lightgray;
}

.Navbar--lower {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.6vw;
}

.Navbar--lower p {
  color: gray;
  font-size: 20px;
}

@media (max-width: 1200px) {
  .Navbar--lower {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 0;
  }

  .Navbar--lower p {
    margin: 10px 20px 10px 20px;
  }
}

@media (max-width: 600px) {
  .Navbar--lower {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 0;
  }

  .Navbar--lower p {
    margin: 5px 10px 5px 10px;
  }
}